.container {
    background: #35396D;
    opacity: .7;
    width: 100%;
    height: 3px;
}

.progress {
    background: #D7FC51;
    transition: width 1s ease;
    height: 3px;
}

.animate {
    animation: progressBar 60s forwards;
}

@keyframes progressBar {
    from {
        width: 0;
    }

    to {
        width: 99%;
    }
}
