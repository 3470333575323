.play {
/*    background-image: linear-gradient(180deg, rgba(8, 9, 18, 0) -9.38%, rgba(8, 9, 18, 0.53) 84.58%, #080912 100%);
    background-position: center;
    background-size: cover;*/
    padding-top: calc(100vh - 59px);
    padding-bottom: 59px;
    position: relative;
    height: 100vh;
    display: flex;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.play .container {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play__block {
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1.3fr;
    align-items: center;
    padding: 0 12px;
}

.play__block__blockTitle__secondText {
    font-family: "Industry", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: .7;
}

.play__block__border {
    border: 2px solid #ffffff;
    border-radius: 2.5rem;
    width: 60px;
    height: 2.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: opacity .3s ease-in;
}

.play_block_sound {
    width: 72px;
    height: 1.25rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity .3s ease-in;
    opacity: .7;
    padding: 1rem 0;
}
.play_block_sound:hover {
    opacity: 1;
}

.play_block_sound p {
    font-family: "Industry", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: white;
}

.play__block__border:hover {
    opacity: .7;
}

@media (min-width:1900px) {
    .play .container {
        max-width: 80%;
    }

    .play_block_sound {
        width: 200px;
    }
}

@media only screen and (max-width: 768px)  {
    .play {
 /*       background-image: linear-gradient(180deg, rgba(8, 9, 18, 0) -9.38%, rgba(8, 9, 18, 0.53) 84.58%, #080912 100%); */
        padding-top: calc(60vh);
        padding-bottom: 0;
    }

    .play__block {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        align-items: center;
    }

    .play .container {
        padding: 0 1rem;
    }

    .play__block__blockTitle__secondText {
        font-size: 0.8125rem;
        line-height: 1.75rem;
        margin-bottom: 26px;
    }

    .play__block__border {
        margin-bottom: 54px;
    }
}
